*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Nunito Sans, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.-top-20 {
  top: -5rem;
}

.left-96 {
  left: 24rem;
}

.left-28 {
  left: 7rem;
}

.top-0 {
  top: 0;
}

.-right-12 {
  right: -3rem;
}

.-bottom-12 {
  bottom: -3rem;
}

.left-0 {
  left: 0;
}

.-z-10 {
  z-index: -10;
}

.z-40 {
  z-index: 40;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.ml-auto {
  margin-left: auto;
}

.-mt-32 {
  margin-top: -8rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-0 {
  margin-top: 0;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-auto {
  height: auto;
}

.h-8 {
  height: 2rem;
}

.h-6 {
  height: 1.5rem;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-min {
  min-height: min-content;
}

.w-\[108rem\] {
  width: 108rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.min-w-max {
  min-width: max-content;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1;
}

.-skew-y-\[9deg\] {
  --tw-skew-y: -9deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.skew-y-\[9deg\] {
  --tw-skew-y: 9deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse) );
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--tw-space-y-reverse) );
}

.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse) );
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse) ) );
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--tw-space-y-reverse) );
}

.overflow-hidden {
  overflow: hidden;
}

.rounded {
  border-radius: .25rem;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(21 21 25 / var(--tw-bg-opacity) );
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity) );
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nav {
  font-size: 13px;
  line-height: 18px;
}

.text-main-header {
  font-size: 48px;
  line-height: 65px;
}

.text-main-sub-header {
  font-size: 18px;
  line-height: 25px;
}

.text-section-sub-header {
  font-size: 14px;
  line-height: 19px;
}

.text-section-header {
  font-size: 30px;
  line-height: 41px;
}

.text-content {
  font-size: 16px;
  line-height: 26px;
}

.text-footer {
  font-size: 12px;
  line-height: 26px;
}

.font-semibold {
  font-weight: 600;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-white-400 {
  color: #ffffffbf;
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(21 21 25 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity) );
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-20 {
  opacity: .2;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-primary\/40 {
  --tw-shadow-color: #4f46e566;
  --tw-shadow: var(--tw-shadow-colored);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

nav a {
  color: #4f4fe580;
  font-weight: 600;
}

nav a:active, nav a:hover {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity) );
  font-weight: 600;
}

.gradfinal {
  background: linear-gradient(211.59deg, #d97992 -27.54%, #ac598b -18.59%, #853d85 -10.93%, #662780 -3.26%, #4d157c 4.41%, #3c097a 13.36%, #320278 22.3%, #2f0078 33.8%, #2f027c 45.31%, #310a88 56.81%, #34179c 68.31%, #3829b9 79.81%, #3d41dd 91.31%, #4256fe 100.26%);
}

.vector-container {
  height: 620px;
  width: 600px;
}

.card {
  width: 100%;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(229 231 235 / var(--tw-border-opacity) );
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
  border-radius: .25rem;
  margin-left: auto;
  margin-right: auto;
  transition: color .15s cubic-bezier(0, 0, .2, 1) 75ms, background-color .15s cubic-bezier(0, 0, .2, 1) 75ms, border-color .15s cubic-bezier(0, 0, .2, 1) 75ms, text-decoration-color .15s cubic-bezier(0, 0, .2, 1) 75ms, fill .15s cubic-bezier(0, 0, .2, 1) 75ms, stroke .15s cubic-bezier(0, 0, .2, 1) 75ms, opacity .15s cubic-bezier(0, 0, .2, 1) 75ms, box-shadow .15s cubic-bezier(0, 0, .2, 1) 75ms, transform .15s cubic-bezier(0, 0, .2, 1) 75ms, filter .15s cubic-bezier(0, 0, .2, 1) 75ms, backdrop-filter .15s cubic-bezier(0, 0, .2, 1) 75ms;
}

.card:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  --tw-shadow: 0 2px 4px #a2a2ed66;
  --tw-shadow-colored: 0 2px 4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 640px) {
  .card {
    width: auto;
    flex: 1;
  }
}

.card a {
  padding: 3rem 2rem;
}

.card-title {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
  font-size: 18px;
  line-height: 28px;
}

.card-content {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity) );
  margin-top: 1.5rem;
  font-size: 16px;
  line-height: 26px;
}

.article {
  text-align: center;
  flex-direction: column;
  padding: 1rem 0;
  display: flex;
}

@media (min-width: 640px) {
  .article {
    text-align: left;
    padding: 3rem 1.25rem;
  }
}

.article li {
  width: 50%;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity) );
  background: url("li.7444a412.svg") 0 no-repeat;
  margin-top: .25rem;
  margin-bottom: .25rem;
  padding-top: .375rem;
  padding-bottom: .375rem;
  padding-left: 1.25rem;
  font-size: 16px;
  line-height: 16px;
}

ul.specialities li {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops) );
  --tw-gradient-from: #4256fe;
  --tw-gradient-to: #4256fe00;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #853d85;
  color: #0000;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 30px;
  font-weight: 600;
  line-height: 41px;
}

ul.specialities li:not(:first-child) {
  margin-left: 0;
}

@media (min-width: 640px) {
  ul.specialities li:not(:first-child) {
    margin-left: 2.5rem;
  }
}

ul.mobile-navbar li {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: .75rem;
  padding-bottom: .75rem;
  display: flex;
}

ul.mobile-navbar li:not(:last-child) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(148 163 184 / var(--tw-border-opacity) );
}

#hero, #about, #specialities, #contact, #design, #code, #management {
  scroll-margin: 8rem;
}

@media (min-width: 640px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mr-3 {
    margin-right: .75rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mt-32 {
    margin-top: 8rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:min-h-0 {
    min-height: 0;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse) ) );
    margin-bottom: calc(0px * var(--tw-space-y-reverse) );
  }

  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.625rem * var(--tw-space-x-reverse) );
    margin-left: calc(.625rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(.5rem * var(--tw-space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse) );
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse) ) );
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:pt-48 {
    padding-top: 12rem;
  }

  .sm\:text-left {
    text-align: left;
  }
}

@media (min-width: 768px) {
  .md\:w-auto {
    width: auto;
  }
}

@media (min-width: 1024px) {
  .lg\:flex {
    display: flex;
  }
}

@media (min-width: 1280px) {
  .xl\:-top-28 {
    top: -7rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:pt-56 {
    padding-top: 14rem;
  }
}

/*# sourceMappingURL=index.ac30e267.css.map */
