@tailwind base;
@tailwind components;
@tailwind utilities;

nav a {
    @apply font-semibold text-primary-300;
}

nav a:active {
    @apply font-semibold text-primary;
}

nav a:hover {
    @apply font-semibold text-primary;
}

.gradfinal {
    background: linear-gradient(211.59deg, #D97992 -27.54%, #AC598B -18.59%, #853D85 -10.93%, #662780 -3.26%, #4D157C 4.41%, #3C097A 13.36%, #320278 22.3%, #2F0078 33.8%, #2F027C 45.31%, #310A88 56.81%, #34179C 68.31%, #3829B9 79.81%, #3D41DD 91.31%, #4256FE 100.26%);
}

.vector-container {
    height: 620px;
    width: 600px;
}

.card {
    @apply border border-gray-300 bg-white rounded w-full mx-auto sm:flex-1 sm:w-auto hover:shadow-card hover:scale-105 transition transform-gpu delay-75 ease-out;
}

.card a {
    @apply py-12 px-8;
}

.card-title {
    @apply text-card-header text-black-900;
}

.card-content {
    @apply mt-6 text-content text-gray-600 text-center;
}

.article {
    @apply flex flex-col py-4 sm:py-12 px-0 sm:px-5 text-center sm:text-left;
}

.article li {
    background: url("img/li.svg") no-repeat left center;
    @apply pl-5 text-content text-gray-600 w-1/2 py-1.5 my-1 text-left;
    line-height: 16px;
}

ul.specialities li {
    @apply text-transparent bg-clip-text bg-gradient-to-tr from-grad-900 to-grad-100 text-section-header font-semibold;
}

ul.specialities li:not(:first-child) {
    @apply ml-0 sm:ml-10;
}

ul.mobile-navbar li {
    @apply flex w-full justify-center items-center py-3;
}

ul.mobile-navbar li:not(:last-child) {
    @apply border-b border-gray-600;
}

#hero, #about, #specialities, #contact, #design, #code, #management {
    scroll-margin: 8rem;
}

